import React from 'react'
import { DesignView } from '@views'

import { graphql } from 'gatsby'

import { Seo } from '@atoms'

export const Head = () => {
  return <Seo title={'Design'} />
}

const DesignPage = (props) => {
  const data = props?.data?.contentfulCaseStudyGrid

  return <DesignView data={data} />
}

export const designQuery = graphql`
  query designQuery {
    contentfulCaseStudyGrid(page: { eq: "Design + Strategy" }) {
      ...CaseStudyGrid
    }
  }
`

export default DesignPage
